import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import styles from './style.module.css'

export default function ExploreAllWorks ({
  light = false
}) {
  const { t } = useTranslation('common')
  return (
    <div className={styles.wrapper}>
      <div className={styles.linkWrapper}>
        <Link
          className={styles.link}
          style={{
            color: light ? '#fff' : '#222'
          }}
          href='/work/all'
        >
          {t('exploreallworks')}
          <span className={styles.arrow}>➔</span>
        </Link>
        <span
          className={styles.lineHover}
          style={{
            backgroundColor: light ? '#fff' : '#222'
          }}
        />
      </div>
    </div>
  )
}
