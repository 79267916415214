import { useEffect } from 'react'

export default function useKeypress (codes = [], action) {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      function onKeyDown (e) {
        if (codes) {
          if (Array.isArray(codes)) {
            if (codes.includes(e.code) && typeof action === 'function' && e.target === document.body) {
              e.preventDefault()
              e.stopPropagation()
              action()
            }
          } else {
            if (e?.code === codes && typeof action === 'function' && e.target === document.body) {
              e.preventDefault()
              e.stopPropagation()
              action()
            }
          }
        }
      }
      window.addEventListener('keydown', onKeyDown)
      return () => window.removeEventListener('keydown', onKeyDown)
    }
  }, [codes, action])
}
