import { forwardRef } from 'react'
import styles from './style.module.css'
import classNames from 'classnames'

export const CursorBall = forwardRef((props, ref) => {
  const { size, color, withShadow, position, children, zIndex, customPadding, rest } = props
  return (
    <div
      ref={ref}
      className={withShadow ? classNames(styles.withShadow, styles.cursorBall) : styles.cursorBall}
      style={{ width: size, height: size, background: color, position, zIndex, padding: customPadding || '1rem' }}
      {...rest}
    >
      {children}
    </div>
  )
})

CursorBall.displayName = 'CursorBall'

export const CursorText = forwardRef((props, ref) => {
  const { children, titleColor, ...rest } = props
  return (
    <span
      {...rest}
      style={{ color: titleColor || '#000' }}
      ref={ref}
      className={styles.title}
    >
      {children}
    </span>
  )
})

CursorText.displayName = 'CursorText'
