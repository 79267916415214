import { useEffect, useRef } from 'react'
import Player from '@vimeo/player'
import { useInView } from 'react-intersection-observer'
import gsap from 'gsap'
import classNames from 'classnames'
import { CursorBall, CursorText } from '../CursorBall'
import { isMobileDevice } from '../../utils/device'
import useKeypress from '../../hooks/useKeyPress'
import styles from './style.module.css'

const CURSOR_SIZE = 76

export default function WorkVideo ({
  mp4Source = null,
  webmSource = null,
  ratio = [16, 9],
  fill = false,
  isLazy = true,
  vimeoId = '',
  iframeProps
}) {
  const cursorPlayIcon = useRef()
  const cursorBall = useRef()
  const cursorText = useRef()
  const vimeoIframe = useRef()
  const vimeoPlayer = useRef()
  const _isMobileDevice = isMobileDevice()
  const _windowHeight = typeof window !== 'undefined' ? window.innerHeight : 0
  const { ref, inView } = useInView({ triggerOnce: true, rootMargin: `${_windowHeight}px` })
  const { ref: videoContainerRef, inView: inViewVimeo } = useInView({ rootMargin: `${_windowHeight}px` })
  // const cssRatioPercentage = (ratio[1] / ratio[0]) * 100
  const cssAspectRatio = `${ratio[0]} / ${ratio[1]}`

  const showPlayIcon = () => {
    gsap.to('._vimeoOverlay', { opacity: 1, ease: 'power3', duration: 0.6 })
    gsap.to(cursorPlayIcon.current, { left: `calc(50% - ${CURSOR_SIZE / 2}px`, top: `calc(50% - ${CURSOR_SIZE / 2}px`, x: 0, y: 0, opacity: 1, ease: 'power3', duration: 0.8 })
  }

  const hidePlayIcon = () => {
    gsap.to('._vimeoOverlay', { opacity: 0, ease: 'power3', duration: 0.6 })
    gsap.to(cursorPlayIcon.current, { left: 0, top: 0, opacity: 0, ease: 'power3', duration: 0.8 })
  }

  const _onMouseMove = (event) => {
    if (_isMobileDevice) return
    const { clientX, clientY } = event
    const bounds = event.target.getBoundingClientRect()
    gsap.to(cursorBall.current, { x: clientX - 70 - bounds.x, y: clientY - 70 - bounds.y, duration: 0.6, ease: 'power3' })
    gsap.to(cursorPlayIcon.current, { left: 0, top: 0, x: clientX - bounds.x - 70, y: clientY - bounds.y - 70, duration: 0.8, ease: 'power3' })
  }

  const _onMouseEnter = (event) => {
    if (_isMobileDevice) return
    const { clientX, clientY } = event
    const bounds = event.target.getBoundingClientRect()
    gsap.to(cursorBall.current, { opacity: 1, x: clientX - 70 - bounds.x, y: clientY - 70 - bounds.y, scale: 1, ease: 'power3', duration: 0.3 })
    gsap.fromTo(cursorText.current, { opacity: 0, scale: 0.8 }, { opacity: 1, scale: 1, ease: 'back', duration: 0.2, delay: 0.2 })
    hidePlayIcon()
  }

  const _onMouseLeave = () => {
    if (_isMobileDevice) return
    vimeoPlayer.current.getPaused().then(function (paused) {
      if (paused) {
        showPlayIcon()
      }
    })
    gsap.to(cursorBall.current, { opacity: 0, scale: 0.2, ease: 'power3', duration: 0.2 })
    gsap.to(cursorText.current, { opacity: 0, scale: 0.8, ease: 'power3', duration: 0.2 })
  }

  // Vimeo iframe init
  useEffect(() => {
    if (!vimeoId || !vimeoIframe.current) return
    vimeoPlayer.current = new Player(vimeoIframe.current)
    vimeoPlayer.current.setVolume(0.5)
    cursorText.current.innerText = 'Play'
    gsap.set(cursorPlayIcon.current, { opacity: 1, left: `calc(50% - ${CURSOR_SIZE / 2}px`, top: `calc(50% - ${CURSOR_SIZE / 2}px`, rotate: -15 })
    vimeoPlayer.current.on('bufferstart', () => {
      cursorText.current.innerText = '...'
    })
    vimeoPlayer.current.on('bufferend', () => {
      vimeoPlayer.current.getPaused().then(function (paused) {
        if (paused) {
          cursorText.current.innerText = 'Play'
        } else {
          cursorText.current.innerText = 'Pause'
          hidePlayIcon()
        }
      })
    })
  }, [vimeoId])

  // Play/Pause function
  const handleVimeoPlayPause = () => {
    vimeoPlayer.current.getPaused().then(function (paused) {
      if (paused) {
        cursorText.current.innerText = 'Play'
        vimeoPlayer.current.play().then(() => {
          cursorText.current.innerText = 'Pause'
          hidePlayIcon()
        })
      } else {
        cursorText.current.innerText = 'Pause'
        vimeoPlayer.current.pause().then(() => {
          cursorText.current.innerText = 'Play'
        })
        if (_isMobileDevice) {
          showPlayIcon()
        }
      }
    })
  }

  // Play/Pause on keyboard Space/P key pressed
  useKeypress(['Space', 'KeyP'], () => {
    if (inViewVimeo && vimeoId) {
      vimeoPlayer.current.pause().then(() => {
        cursorText.current.innerText = 'Play'
        showPlayIcon()
      })
    }
  })

  // Pause the video if is not inView
  useEffect(() => {
    if (!inViewVimeo && vimeoId) {
      showPlayIcon()
      vimeoPlayer.current.pause().then(() => {
        cursorText.current.innerText = 'Play'
      })
    }
  }, [vimeoId, inViewVimeo])

  if (vimeoId) {
    return (
      <div
        ref={ref}
        style={{ position: 'relative' }}
        className={classNames({
          [styles.fill]: fill
        })}
      >
        <div
          ref={videoContainerRef}
          style={
            !fill
              ? {
                  aspectRatio: cssAspectRatio,
                  // paddingTop: `${cssRatioPercentage}%`, // css fallback
                  cursor: 'none'
                }
              : {}
          }
          className={classNames(styles.aspectRatio, {
            [styles.fill]: fill
          })}
          onClick={handleVimeoPlayPause}
          onMouseEnter={_onMouseEnter}
          onMouseMove={_onMouseMove}
          onMouseLeave={_onMouseLeave}
        >
          <div
            className={classNames(styles.vimeoContainer, {
              [styles.fill]: fill
            })}
          >
            <iframe
              ref={vimeoIframe}
              style={
                fill
                  ? {
                      aspectRatio: cssAspectRatio
                    }
                  : {}
              }
              className={classNames({
                [styles.fillHeight]: fill === 'height' || fill === true,
                [styles.fillWidth]: fill === 'width'
              })}
              src={`https://player.vimeo.com/video/${vimeoId}?api=1&&autoplay=0&controls=false&loop=1&dnt=1`}
              allow='autoplay'
              frameBorder={0}
              allowFullScreen='allowfullscreen'
              {...iframeProps}
            />
          </div>
          <div className={classNames('_vimeoOverlay', styles.vimeoOverlay)} />
        </div>
        <CursorBall position='absolute' ref={cursorPlayIcon} size={CURSOR_SIZE}>
          <CursorText>
            Play
          </CursorText>
        </CursorBall>
        <CursorBall ref={cursorBall} size={CURSOR_SIZE} withShadow position='absolute'>
          <CursorText ref={cursorText} />
        </CursorBall>
      </div>
    )
  }

  return (
    <div
      ref={ref}
      style={{
        aspectRatio: cssAspectRatio,
        // paddingTop: `${cssRatioPercentage}%`, // css fallback
        cursor: 'default'
      }}
      className={styles.aspectRatio}
    >
      {isLazy
        ? inView
          ? (
            <video className={styles.video} autoPlay muted loop>
              {webmSource && <source src={webmSource} type='video/webm' />}
              {mp4Source && <source src={mp4Source} type='video/mp4' />}
            </video>
            )
          : null
        : (
          <video className={styles.video} autoPlay muted loop>
            {webmSource && <source src={webmSource} type='video/webm' />}
            {mp4Source && <source src={mp4Source} type='video/mp4' />}
          </video>
          )}
    </div>

  )
}
