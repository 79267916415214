import styles from './style.module.css'

export default function PageSeparator ({
  primary = '#000000',
  secondary = '#D49DFF',
  background = 'none',
  cutPrevContent = false
}) {
  return (
    <div style={{
      position: 'relative',
      height: cutPrevContent ? '0px' : 'auto',
      top: cutPrevContent ? '-112px' : '0px'
    }}
    >
      <div
        className={styles.container}
        style={{
          backgroundColor: background
        }}
      >
        <div
          style={{
            backgroundColor: `${secondary}`
          }}
          className={styles.secondary}
        />
        <div
          style={{
            backgroundColor: `${primary}`
          }}
          className={styles.primary}
        />
        <div
          style={{
            backgroundColor: `${primary}`
          }}
          className={styles.overlayBottom}
        />
      </div>
    </div>
  )
}
