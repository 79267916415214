import styles from './style.module.css'

export default function WorkParagraph ({
  title = null,
  children = null
}) {
  const _title = title && (
    <h2 className={styles.title}>
      {title}
    </h2>
  )
  return (
    <div className={styles.container}>
      {_title}
      <div className={styles.paragraph}>
        {children}
      </div>
    </div>
  )
}
