import layoutStyles from '../../styles/Layout.module.css'

export default function DefaultSiteContainer ({
  children = null
}) {
  return (
    <div className={layoutStyles.siteContainer}>
      <main className={layoutStyles.main}>
        <div className={layoutStyles.container}>
          {children}
        </div>
      </main>
    </div>
  )
}
