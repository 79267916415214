import Head from 'next/head'
import { useRouter } from 'next/router'

export default function SEO ({
  title = '',
  keywords = '',
  description = '',
  image = '/meta/seo-image.jpeg',
  ogUrl = '',
  ogType = 'website',
  ogSiteName = 'Anubi | Creative Studio',
  ogDescription = '',
  ogTitle = '',
  ogImage = '',
  twitterCard = 'summary_large_image',
  twitterCreator = '@anubistudio',
  twitterTitle = '',
  twitterDescription = '',
  twitterImage = '',
  children = null
}) {
  const router = useRouter()
  const url = ogUrl || `https://anubi.io${router.asPath}`

  return (
    <Head>
      <title>{title}</title>

      <meta name='keywords' content={keywords} />
      <meta name='image' content={image} />
      <meta name='description' content={description} />

      <meta name='og:url' content={url} />
      <meta name='og:type' content={ogType} />
      <meta name='og:site_name' content={ogSiteName} />
      <meta name='og:title' content={ogTitle || title} />
      <meta name='og:description' content={ogDescription || description} />
      <meta name='og:image' content={ogImage || image} />

      <meta name='twitter:card' content={twitterCard} />
      <meta name='twitter:creator' content={twitterCreator} />
      <meta name='twitter:title' content={twitterTitle || title} />
      <meta name='twitter:description' content={twitterDescription || description} />
      <meta name='twitter:image' content={twitterImage || image} />
      {children}
    </Head>
  )
}
