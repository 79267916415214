import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import AnubiLogo from '../../public/anubi.svg'
import terms from '../../stores/terms'
import styles from './style.module.css'

export default function Footer () {
  const { t } = useTranslation('common')

  const setForceBannerVisibility = terms((state) => state.setForceBannerVisibility)

  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.footerLeft}>
          <span>ANUBI SRLS</span>
          <span>Badia Polesine, 45021 RO</span>
          <div className={styles.vat}>
            <span>{t('vat')}</span>
            <span>01612180297</span>
          </div>
          <span translate='no'>hello@anubi.io</span>
        </div>
        <div className={styles.footerRight}>
          <span>{t('finduson')}</span>
          <a
            target='_blank'
            href='https://www.instagram.com/anubi.io/'
            rel='noreferrer'
            translate='no'
          >
            Instagram
          </a>
          <a
            target='_blank'
            href='https://www.linkedin.com/company/anubi-io' rel='noreferrer'
            translate='no'
          >
            LinkedIn
          </a>
          <a
            target='_blank'
            href='https://dribbble.com/anubi'
            rel='noreferrer'
            translate='no'
          >
            Dribbble
          </a>
        </div>
      </div>
      <div
        className={styles.terms}
      >
        <a
          href='https://www.iubenda.com/privacy-policy/23452349'
          rel='noopener noreferrer'
          target='_blank'
        >{t('privacyPolicy')}
        </a>
        {' - '}
        <a
          href='https://www.iubenda.com/privacy-policy/23452349/cookie-policy'
          rel='noopener noreferrer'
          target='_blank'
        >{t('cookiesPolicy')}
        </a>
        {' - '}
        <span className={styles.changeCookie} onClick={() => setForceBannerVisibility(true)}>
          {t('changeCookie')}
        </span>
      </div>
      <div
        className={styles.madeWithLove}
      >
        {t('footermadeby')}&nbsp;
        <a
          target='_blank'
          href='https://anubi.io'
          rel='noopener noreferrer'
        >
          Anubi&nbsp;
          <Image
            src={AnubiLogo}
            alt='Anubi Logo'
            width={14}
            height={14}
            style={{
              maxWidth: '100%',
              height: 'auto'
            }}
          />
        </a>
      </div>
    </footer>
  )
}
