import { useRef } from 'react'
import { useIsoLayoutEffect } from '../../hooks'
import Link from 'next/link'
import layoutStyles from '../../styles/Layout.module.css'
import PageSeparator from '../PageSeparator'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import useTranslation from 'next-translate/useTranslation'
import styles from './style.module.css'
gsap.registerPlugin(ScrollTrigger)

export default function EndPageCta ({
  cutPrevContent = false
}) {
  const wrapperRef = useRef()
  const ctaRef = useRef()

  const { t } = useTranslation('common')

  useIsoLayoutEffect(() => {
    const ctx = gsap.context(() => {
      gsap.to(ctaRef.current, {
        scrollTrigger: {
          trigger: ctaRef.current,
          start: 'top bottom',
          end: 'bottom top',
          scrub: 4
        },
        xPercent: -20
      })
    })
    return () => ctx.revert()
  }, [])

  return (
    <>
      <PageSeparator cutPrevContent={cutPrevContent} secondary='transparent' />
      <div ref={wrapperRef} className={styles.wrapper}>
        <div className={layoutStyles.container}>
          <div className={styles.container}>
            <div className={styles.title}>
              {t('endpagecta')}
            </div>
            <div className={styles.ctaContact}>
              <Link
                href='/contact'
                passHref
              >
                <div ref={ctaRef} className={styles.ctaContactText}>
                  {Array(8).fill().map((_, i) => (
                    <span key={`_${i}_cta`}>
                      {t('sendusmessage')}
                      <span className={styles.ctaArrow}>{' ➛ '}</span>
                    </span>
                  ))}
                </div>
              </Link>
            </div>
            <div className={styles.alternative}>
              <div className={styles.altTitle}>
                {t('reachviaemail')}
              </div>
              <div className={styles.altContent}><span translate='no'>hello@anubi.io</span></div>
            </div>
          </div>
        </div>
      </div>
      <PageSeparator cutPrevContent primary='#FCFCFC' secondary='transparent' />
    </>
  )
}
